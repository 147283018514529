import { Box, Flex } from '@chakra-ui/react';
import ToolsAndTechnologies from '../ToolsAndTechnologies';

const AboutToolsAndTechnologies = () => {
  return (
    <Flex
        w="100%"
        h="auto"
        alignSelf="center"
        justifyContent="center"
      >
          <Flex flexDir="column" width="100%" w={['100%', '54%']} justifyContent="center">
          <Flex flexDir="column">
              <Box
                fontSize={['70px', '90px']}
                marginBlockStart={0}
                marginBlockEnd={0}
                fontWeight="800"
                fontFamily="inter"
              >
                MY
              </Box>
              <Box
                fontSize={['59px', '69px']}
                marginBlockStart={0}
                marginBlockEnd={0}
                fontWeight="800"
                mt={['-45px !important', '-58px !important']}
                opacity="0.6"
              >
                TOOLBOX
              </Box>
            </Flex>
            <ToolsAndTechnologies />
        </Flex>
        </Flex>
     
  );
};

export default AboutToolsAndTechnologies;
