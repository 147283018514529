import { Flex, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex
      justifyContent="center"
      bg="#101B2F"
      w="100%"
      py={8}
    >
      <Flex alignItems="center" flexDir="column" w="100%">
        <Text py={2}>Designed and developed with ❤️ by Nicanor Korir</Text>
        {/* <Link
          href="https://github.com/Nicanor008/nicanor-netlify-app"
          target="_blank"
          color="white"
          fontWeight={600}
          w="100%"
          textAlign="center"
        >
          Github Code
        </Link> */}
      </Flex>
    </Flex>
  );
};

export default Footer;
